
import { defineComponent, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { debounce } from "lodash-es";
import moment from "moment";
import { useStore } from "vuex";
import {
  applicableTypeClassList,
  searchDealerByCodeAndName,
  getApplicableDspData,
  checkApplicableBlank,
  saveBaseInfo,
  getAllCitys,
  getDealerApplicableTypeClassList,
  getLocalDealerByCode,
  getLocalDealerByName,
  getDealerMainData,
  queryBlankDealer,
} from "@/API/localRegistration";
import { message } from "ant-design-vue";
export default defineComponent({
  setup() {
    const store = useStore();
    const currentUserBu: string = store.state.user.bu.orgnmen;
    const router: any = useRouter();
    const dealerName = ref<string>("");
    const dealerNameOptions = ref<any[]>([]);
    const fetching = ref<boolean>(false);
    const dealerCode = ref<string>("");
    const province = ref<string>("");
    const city = ref<string>("");
    const region = ref<string>("");
    const subRegion = ref<string>("");
    const swtCode = ref<string>("");
    const dealerGroup = ref<string>("");

    const buList = ref<string[]>([]);

    const specialCitys = ref<any[]>([]);
    const specialCityOptions = ref<any[]>([]);

    const isBlankCity = ref<boolean>(false);

    const typeClassInfo = ref<any[]>([]);

    const typeClassList = ref<any[]>([]);
    const typeClass = ref<string[]>([]);
    const preTypeClass = ref<string[]>([]);

    const handleGetDealerOptions = debounce((value) => {
      dealerNameOptions.value = [];
      fetching.value = true;
      const params = {
        dealerNameCn: value,
      };
      searchDealerByCodeAndName(params).then((res: any) => {
        dealerNameOptions.value = res.data.map((item: any) => {
          return {
            label: item.dealerInfo.dealerNameCn,
            value: item.dealerInfo.dealerNameCn,
            city: item.dealerInfo.cityCn,
            dealerCode: item.dealerInfo.dealerCode,
            province: item.dealerInfo.provinceCn,
            swtCode: item.dealerInfo.swtCode,
            dealerGroup: item.dealerInfo.dealerGroup,
            buList: item.dealerBusinessUnit.map(
              (item: any) => item.businessUnitEn
            ),
          };
        });
      });
    }, 300);

    const getSelectedDealerInfo = (value: any) => {
      return getLocalDealerByName({ dealerName: value });
    };

    const getTypeClassList = (buList: any) => {
      return getDealerApplicableTypeClassList(buList);
    };

    const dealerId = ref<number>();
    let typeClassInfoFirstLength = 0;
    const handleDealerChange = (value: any, dealer: any) => {
      typeClass.value = [];
      dealerCode.value = dealer.dealerCode;
      province.value = dealer.province;
      city.value = dealer.city;
      region.value = dealer.region;
      swtCode.value = dealer.swtCode;
      dealerGroup.value = dealer.dealerGroup;
      buList.value = dealer.buList;
      typeClassInfo.value = [];

      let typeClasses: any;
      Promise.all([
        getSelectedDealerInfo(value),
        getTypeClassList(dealer.buList),
      ])
        .then(([dealerInfoRes, typeClassRes]): any => {
          typeClasses = typeClassRes as any;
          typeClassList.value = typeClasses;
          if ((dealerInfoRes as any).data.length !== 0) {
            const id = (dealerInfoRes as any).data[0].id;
            dealerId.value = id;
            return getDealerMainData({ dealerId: id });
          }
        })
        .then((res: any) => {
          if (res.data !== "") {
            typeClassInfo.value = res.data.applicableVOS.map((item: any) => {
              return { hasAdded: true, ...item };
            });
            typeClassInfoFirstLength = typeClassInfo.value.length;
            typeClass.value = typeClassInfo.value.map(
              (item) => item.applicableTypeClass
            );
            preTypeClass.value = typeClass.value;
            typeClassList.value = typeClasses.map((type: string) => {
              let disabled = false;
              if (typeClass.value.includes(type)) {
                disabled = true;
              }
              return { label: type, value: type, disabled };
            });
          }
        });
    };

    const handleTypeClassChange = (value: string[]) => {
      const addedTypeClass = value.find(
        (item) => !preTypeClass.value.includes(item)
      );
      if (!addedTypeClass) {
        // 取消勾选
        const applicableTypeClasses = typeClassList.value.map(
          (item) => item.value || item
        );
        const deletedTypeClass = preTypeClass.value
          .filter((item) => applicableTypeClasses.includes(item))
          .find((item) => !value.includes(item));
        if (!deletedTypeClass) {
          return;
        }
        typeClassInfo.value = typeClassInfo.value.filter(
          (item) => item.applicableTypeClass != deletedTypeClass
        );
        const isBlankList = typeClassInfo.value.map((item) => item.isBlank);
        isBlankCity.value = isBlankList.includes((item: any) => item === true);
        const index = preTypeClass.value.indexOf(deletedTypeClass);
        if (index !== -1) {
          preTypeClass.value.splice(index, 1);
        }
      } else {
        const params = {
          applicableTypeClass: addedTypeClass,
          "dealerBasicInfo.province": province.value,
          "dealerBasicInfo.city": city.value,
          "dealerBasicInfo.dealerCode": dealerCode.value,
        };
        checkApplicableBlank(params).then((res: any) => {
          const isBlank = res.data.checkStatus;
          const tempRegion = res.data.region;
          const subRegion = res.data.subRegion;
          region.value = res.data.region;
          // subRegion.value = res.data.subRegion
          const params2 = {
            applicableTypeClass: addedTypeClass,
            province: province.value,
            city: city.value,
            region: region.value,
          };
          getApplicableDspData(params2).then((res: any) => {
            if (res.data) {
              const result = res.data;
              result.isExpand = true;
              result.isBlank = isBlank;
              result.region = tempRegion;
              result.subRegion = subRegion;
              result.effectiveTime = moment(new Date()).format("YYYY-MM-DD");
              result.applicableTypeClass = addedTypeClass;
              typeClassInfo.value.push(result);
              const isBlankList = typeClassInfo.value.map(
                (item) => item.isBlank
              );
              isBlankCity.value = isBlankList.includes(true);
            }
          });
        });
        preTypeClass.value.push(addedTypeClass);
      }
    };

    const handleJump = (path: string) => {
      router.push(path);
    };

    const genSaveParams = () => {
      //! 保存时截取新增的部分
      //! 如果长度相等: 提示 不保存；
      const typeClassInfoEndLength = typeClassInfo.value.length;

      if (typeClassInfoFirstLength === typeClassInfoEndLength) {
        message.warning("Please select applicable type class");
        return false;
      }
      const toSaveTypeClass = typeClassInfo.value.slice(
        typeClassInfoFirstLength,
        typeClassInfoEndLength
      );
      const checkList = toSaveTypeClass
        .filter((item) => item.isBlank)
        .map((item) => item.applicableTypeClass);
      const applicables = toSaveTypeClass.map((item) => {
        return {
          applicableTypeClass: item.applicableTypeClass,
          region: item.region,
          subRegion: item.subRegion,
          status: "0",
          effectiveTime:
            moment(item.effectiveTime).format("YYYY-MM-DD") === "Invalid date"
              ? null
              : moment(item.effectiveTime).format("YYYY-MM-DD"),
        };
      });
      const params = {
        operationType: "add",
        specialAreas: specialCitys.value.map((item) => {
          return {
            city: item,
          };
        }),
        basicInfo: {
          dealerCode: dealerCode.value,
          dealerName: dealerName.value,
          swtCode: swtCode.value,
          province: province.value,
          city: city.value,
          dealerGroup: dealerGroup.value || "",
        },
        checkList,
        applicables,
      };
      return params;
    };

    const handleSave = () => {
      const params = genSaveParams();
      if (params === false) {
        return
      }
      saveBaseInfo(params).then((res) => {
        message.success("You have added dealer successfully");

        typeClassList.value.forEach((type: any) => {
          if (typeClass.value.includes(type.label)) {
            type.disabled = true;
          }
        });
        typeClassInfoFirstLength = typeClassInfo.value.length;
      });
    };

    const handleNext = () => {
      const saveParams = genSaveParams();
      if (saveParams === false) {
        return
      }
      const typeClassInfoEndLength = typeClassInfo.value.length;
      const toSaveTypeClass = typeClassInfo.value.slice(
        typeClassInfoFirstLength,
        typeClassInfoEndLength
      );
      const params = {
        applicableTypeClass: toSaveTypeClass.map(
          (item) => item.applicableTypeClass
        ),
        city: city.value,
        dealerCode: dealerCode.value,
        province: province.value,
        swtCode: swtCode.value,
      };
      queryBlankDealer(params).then((res: any) => {
        const checkList = typeClassInfo.value.map(
          (item) => item.applicableTypeClass
        );
        const query = {
          city: city.value,
          dealerName: dealerName.value,
          dealerId: dealerId.value,
          dealerCode: dealerCode.value,
          swtCode: swtCode.value,
          tableData: JSON.stringify(res),
          checkList: JSON.stringify(checkList),
          saveParams: JSON.stringify(saveParams),
          typeClassInfo: JSON.stringify(typeClassInfo.value),
        };
        router.push({
          name: "Focus Area Set Dealer Adjustment Add2",
          query: query,
        });
      });
    };

    const init = () => {
      applicableTypeClassList().then((res: any) => {
        typeClassList.value = res;
      });
      getAllCitys().then((res: any) => {
        specialCityOptions.value = res;
      });
    };
    init();

    return {
      currentUserBu,
      handleJump,
      handleSave,
      handleNext,
      isBlankCity,
      typeClassList,
      typeClass,
      typeClassInfo,
      dealerName,
      dealerCode,
      swtCode,
      province,
      city,
      dealerNameOptions,
      fetching,
      handleGetDealerOptions,
      specialCityOptions,
      specialCitys,
      handleDealerChange,
      handleTypeClassChange,
    };
  },
});
